'use client';

import { signIn } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { SignInBox } from '@/components/SignInBox';

export default function SignInPage() {
  const callbackUrl = useCallbackUrl();
  const [loading, setLoading] = useState(false);

  // todo: handle case when user is already signed in and navigates to /signin
  // we should redirect to home

  const handleSignIn = () => {
    setLoading(true);
    void signIn('azure-ad', { callbackUrl });
  };

  return <SignInBox loading={loading} onSubmit={handleSignIn} />;
}

function useCallbackUrl() {
  const searchParams = useSearchParams();

  const homeUrl = '/';
  let callbackUrl = searchParams?.get('callbackUrl') || homeUrl;
  // if callbackUrl is the same as signin url, redirect user to homeUrl
  if (callbackUrl.endsWith('/auth/signin')) callbackUrl = homeUrl;

  return callbackUrl;
}
